import React, { useState, useContext } from "react";
import * as S from "./AddToCartButton.styles";
import Context from "../../context/Context";
import submitOrders from "../../functions/submitOrders";
import { Builder } from "@builder.io/react";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import { combineCartItems } from "../../utils/upsellCart";
import { Store } from "react-notifications-component";

const AddToCartButton = ({
  offerIndex,
  submitCart,
  children,
  showConfirmation,
  showNotification,
  notificationTitle,
  notificationType,
  isDisabled = true
}) => {
  const { selectorData, upsellCart, setUpsellCart, userToken } = useContext(
    Context
  );

  const [added, setAdded] = useState(false);
  const [showConfirmationPopup, setConfirmationShowPopup] = useState(false);

  const getCurrentOrder = () => {
    const { offers } = selectorData;
    const currentOrder = offers[offerIndex] || {};
    Object.assign(currentOrder, { offerIndex: offerIndex });
    return currentOrder;
  };

  const addToCart = () => {
    const cartItems = combineCartItems(upsellCart, getCurrentOrder());
    setUpsellCart(cartItems);
  };

  const removeFromCart = () => {
    const newCart = upsellCart.filter(item => {
      const currentOrder = getCurrentOrder();
      return item.variantId !== currentOrder.variantId;
    });
    setUpsellCart(newCart);
  };

  const confirmOrderSubmit = () => submitOrders(userToken, upsellCart);

  const declineOrderSubmit = () => submitOrders(userToken, []);

  const handleClick = () => {
    if (Builder.isEditing) return;
    if (showNotification && !upsellCart.length) {
      Store.addNotification({
        insert: "top",
        container: "top-full",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        ...(notificationType !== "custom" && { type: notificationType }),
        ...(notificationType === "custom" && {
          content: (
            <S.WarningNotification>{notificationTitle}</S.WarningNotification>
          )
        }),
        dismiss: {
          duration: 2000
        }
      });
      return;
    }

    if (submitCart) {
      if (showConfirmation) {
        return setConfirmationShowPopup(true);
      }

      setAdded(true);
      confirmOrderSubmit();
      return;
    }

    setAdded(!added);
    if (added) return removeFromCart();

    addToCart();
  };
  return (
    <>
      <S.Container onClick={handleClick} disabled={isDisabled && added}>
        {children}
      </S.Container>
      {showConfirmationPopup && (
        <ConfirmationPopup
          currentOrders={upsellCart}
          showPopup={showConfirmationPopup}
          setShowPopup={setConfirmationShowPopup}
          confirmOrder={confirmOrderSubmit}
          declineOrder={declineOrderSubmit}
        />
      )}
    </>
  );
};

export default AddToCartButton;
