import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? `
        opacity: 50%;
    `
      : ``}
`;

export const WarningNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fb6728;
  height: 54px;
  font-family: "Neuzeit Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;

  @media (max-width: 768px) {
    height: 48px;
  }
`;
